

.containerBox{
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}

.iconefont{
    font-size: 30px !important;
    color: whitesmoke;


}


.box {
    margin: 20px;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    


  
    --border-angle: 0turn; /* For animation. */
    --main-bg: linear-gradient(to right, #0F0D33 50%, #17145D 50%);  
    border: solid 5px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 50%, white, #B38728 100%, transparent);
  
    background: 
      /* padding-box clip this background in to the overall element except the border. */
      var(--main-bg) padding-box,
      /* border-box extends this background to the border space */
      var(--gradient-border) border-box, 
      /* Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background. */
      var(--main-bg) border-box;
  
    background-position: center center;
  
    animation: bg-spin 4s linear infinite;


    @media (max-width: 768px) {
      width: 105px;
      height: 105px;
      margin: 10px;

    }
  }


  .boxitems{
    font-family: "Poppins";
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:whitesmoke;

  }
  @media (max-width: 768px) {
    .containerBox{
      margin-top: 20px;
    }
    .boxitems{
      font-size: 16px;
      padding: 15px;
    }


  }



  
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  

  

  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
  