@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

legend{
    font-style: italic;
    color: chocolate;
    margin: auto;
    text-align: center;
}
.test{
    background-color: #03031A !important;
}
.sectionThematique{
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    font-size: 25px !important;
    background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 100%) !important;
}

.enumeration{
    list-style:disc;
    margin: 0px 30px;
}
.enumeration li{
    padding: 2px 0px;
}

.infoboutoncopie{
    color:yellow !important;
    background-color: #0F056B !important;
}

.formula{
    padding: 20px 0px;
    text-align: center;
    font-weight: 500;
}

.tableDesMatieres{
    font-size: 20px;
    font-family: "Poppins";
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: '40px 90px';
    background: #03031A;
}
.parag_table_matiere{
    color: aliceblue;
}
.sectionetoile{
    background: #03031A;
}
.tableDesMatieres h1{
    color: #EFC417;
    padding: 20px;
}
.question{
    margin: 10px 0px;
    padding: 10px 0;
    font-weight: 400;
    text-align: center;
    color: white;
    background-image: linear-gradient( 112.7deg,  rgba(253,185,83,1) 11.4%, rgba(255,138,0,1) 70.2% );

}
.terminal{
    margin: auto;
}
.maincontainer_color{
    background: rgb(238, 238, 241);
    display: flex;
    justify-content: center;

    padding: 40px 0px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
}
.maincontainer_color_startline{
    background: rgb(238, 238, 241) !important;
    display: flex;
    padding: 40px 0px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
}





.maincontainer{
    background: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
}
.maincontainer_startline{
    background: white !important;
    display: flex;
    padding: 40px 0px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

}

.p1_g{
    font-size: 20px;
    font-family: "Poppins";
    width: 50%;
    padding: 15px;
    text-align: justify;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}



.p1_d{
    font-size: 20px;
    font-family: "Poppins";
    width: 50%;
    display: flex;
    flex-direction: column;
    
    padding: 15px;
    text-align: justify;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}
.p1_g img, .p1_d img{
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.etape{
    padding: 20px 20px;
    text-align: left;
    font-weight: 700;
    color:#37ABD4;
    text-decoration:underline;
    padding-bottom: 10px;
}

.important_danger{
    padding: 0px 2px;
    background: rgb(176, 15, 15);
    color: whitesmoke;
    font-weight: 500;
}
.important_ok{
    padding: 0px 2px;
    background: rgb(42, 171, 16);
    color: black;
    font-weight: 500;
}
.titre_important{
    padding: 0px 2px;
    color: red;
    text-decoration: underline;
    font-weight: 600;
    text-transform:uppercase;
}
.texte_important{
    padding: 0px 2px;
    color: purple;
    font-weight: 600;
}
.texte_important_rs{
    padding: 0px 2px;
    color: red;
    text-decoration: underline;
    font-weight: 600;
}

.sectiongenerale{
    font-family: "Poppins";
    text-align: center;
    margin: 20px 0px;
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,252,200,1) 0%, rgba(255,247,94,1) 90% );
}
.sous_rubrique{
    padding: 20px 20px;
    color: purple;
    font-weight: 600;
    text-decoration: underline;
}
.rubrique{
    padding: 20px 20px;
    color: purple;
    text-transform: uppercase;
    font-weight: 900;
    text-decoration: underline;
}

.mot_cles{
    padding: 0px 2px;
    color: blue;
    font-weight: 700;
}


.notAvailable{
    background: #03031A;
    color: whitesmoke;
    min-height: 82vh;
    margin: auto;
    text-align: center;
}
.container_notavailable{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}
.notAvailable img{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60%;
    min-width: 40%;
}





/* 
Pour les tableaux 1 colonne, plusieurs lignes
*/
.singlecoltable{
    th{
        background-image: linear-gradient( 109.6deg,  rgba(102,51,153,1) 11.2%, rgba(2,0,4,1) 91.1% );
        color: whitesmoke;
    }
    td, li{
        padding: 0px 10px;
        text-align: left !important;
    }
    tbody tr:nth-child(even) {
        background-color: inherit;
      }
    tbody tr:nth-child(odd) {
        background-color: inherit;
      }

}




/*
    Console view
*/
.intituleTerminal{
    margin-top: 10px !important;
    background-color: #1F2937 !important;
    color:#68DC14 !important ;
    padding-left: 20px !important;
    font-family: "Inconsolata" !important;
}
.ligneDeCommande{
    display: flex;
    justify-content:space-between;
    align-items: center;
    background-color: black;
    padding-left: 20px;
    @media only screen and (max-width: 768px) {
        font-size: 16px;
    }
}
.ligneDeCommande span{
    font-family: "Inconsolata";
    color: wheat;

    
}
.consoleview{
    border-top: 1px solid wheat;
}
.copy-button{
    margin: 10px;
    padding: 0px 15px;
    border: 2px solid white;
    font-family: "Roboto";
    font-size: 18px;
    border-radius: 20px;
    background-color:#68DC14 ;
    color: black ;
}

/* 
Pour les tableaux plusieurs colonnes
*/

table {
    margin: 20px 0px;
    width: 100%;
    border-collapse: collapse;
    border: 2px solid #ddd;
    font-size: 20px !important;
  }
  thead tr th{
    text-align: center !important;
  }
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  tbody td:first-child{
    text-align: center !important;
  }
  
  th:first-child, td:first-child {
    width: 20%; /* Première colonne 20% de la largeur */
  }
  
  th:last-child, td:last-child {
    width: 80%; /* Deuxième colonne 80% de la largeur */
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tbody tr:nth-child(even) {
    background-color: #FFFC9B;
  }
  tbody tr:nth-child(odd) {
    background-color: #FFFDB8;
  }
  