
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Poppins:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gabriela&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
:root{
    --bleu_profond:#12172A;
    --bleu_abysse:#12114C;
    --bleu_mer: #1B156B;
    --bleu_surface:#313BD9;
    --bleu_cyan:#00FFFF;
    --ronifycolor:#9A6A43;
/*👉    --sectionrealisation:#090909;👈*/
    --sectionrealisation:#26262E;

}
*{
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    outline: none; /*👉supprimer le contour du a lorsqu'il est cliqué 👈*/
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

html{
    font-size: 62.5%; /*👉62.5% de la taille normale de 16px soit 10px👈*/
    overflow-x: hidden ; 
    /* user-select: none; */
}


body{
    position: relative;
    background: white !important;
}


/* ============= Bouton switch mail / dessin ============== */
.sectionBoutonSwitchMailDraw{
    display: flex;
    align-items: center;
    justify-content: center;
}


.checkbox {
    width: 223px;
    height: 60px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
  }
  
  #checkbox_toggle {
    display: none;
  }
  
  .checkbox .toggle {
    width: 100px;
    height: 50px;
    position: absolute;
    border-radius: 30px;
    left: 5px;
    cursor: pointer;
    background: linear-gradient(to right, #BF953F, #FCF6BA, #FBF5B7, #AA771C) !important;
    border:1px solid white;

    transition: 0.4s;
  }
  
  .checkbox .slide {
    width: 230px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
  }
  
  .checkbox .slide .text {
    font-size: 16px;
    font-weight: 700;
    z-index: 100;
    cursor: pointer;
  }
  
  .check:checked + .checkbox .slide .toggle {
    transform: translateX(113px);

  }
/* ================ MENU ============  */
header{
    width: 96%;
    background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
    position: fixed; /* 1 */
    top: 2rem; /* 2 */
    left: 50%; /* 3 */
    transform: translateX(-50%); /* 4 */
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index:1000;
    transition: 0.6s;
}

header .navbar ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
}
header .navbar ul li{
    margin: 0 1rem; /*👉 espacement vertical de 1rem 👈*/
}
header .navbar ul li a{
    font-size: 2rem;
    color: var(--ronifycolor);
    transition: 0.2s li;
}
header .navbar ul li .active,
header .navbar ul li a:hover{
    color: var(--bleu_abysse);
    font-weight: 500;
}
header .logo{
    font-size: 2rem;
    text-transform: uppercase;
}
header .logo i{
    color: var(--bleu_surface);;
    padding: 0 0.2rem;
}
header .fa-bars{
    font-size: 3rem;
    color: var(--ronifycolor);
    cursor: pointer;
    display: none;
    transition: 0.2s;
}


.header-active{
    index:30000;
    top:0;
    width: 100%;
    border-radius: 0;
    box-shadow: 0 .1rem .3rem rgba(0, 0, 0, .3);
}


header .fa-times{
    transform: rotate(180deg);
    color: var(--ronifycolor);
    font-size: 3rem;
    cursor: pointer;
    display: none;
}





/* ===================> CONTAINEUR DES PRESTATIONS <=============================== */

/* Format SMARTPHONE */
@media (max-width: 768px) {
    .carlito{
        position: relative;
        width: 150px !important;
        height: 250px;
        margin: 8px 8px !important;
    }
    .bglito {
        padding: 10px !important;
        left: 3px !important;
        width: 150px !important;
    }
    .bglito h3 {
        font-size: 1.3rem !important;
        line-height: 20px !important;
        letter-spacing: 1px !important;
    }
    .bglito p{
        font-size: 10px !important;
    }
    .bloblito {
        width: 200px !important;
        height: 200px !important;
    }


}


.box-container{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}


.carlito{
    position: relative;
    width: 200px;
    height: 250px;
    border-radius: 14px;
    z-index: 1111;
    overflow: hidden;
    display: flex;
    margin: 50px 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}
.bglito {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-around;
    padding: 15px;
    top: 5px;
    left: 5px;
    width: 190px;
    height: 240px;
    z-index: 2;
    background: rgba(255, 255, 255, .95);
    backdrop-filter: blur(24px);
    border-radius: 10px;
    overflow: hidden;
    outline: 2px solid white;
    
}


.bglito h3 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 2px;
    font-family: "Poppins";
    text-align: center;
}
.bglito p{
    font-style: "Roboto";
    text-align: center;
    font-size: 12px;
    color: #10105A;
    font-weight: 300;
}
.bloblito {
    cursor: pointer !important;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #1466b8;
    opacity: 1;
    filter: blur(12px);
    animation: blob-bounce 2s infinite linear;
}
@keyframes blob-bounce {
    0% {
        transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }

    25% {
        transform: translate(-100%, -100%) translate3d(100%, 0, 0);
    }

    50% {
        transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
    }

    75% {
        transform: translate(-100%, -100%) translate3d(0, 100%, 0);
    }

    100% {
        transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
}





/* ============> fin des règles CSS DES PRESTATIONS <=============== */

/* MEDIA QUERRIES */
/*👉@media (min-width: 900px){
  .maxcontainer{
      top:720px;
  }
}👈*/

@media(min-width:767px) and (max-width:901px){
    header .navbar ul li a{
        font-size: 1.5rem;
    }
    header .logo{
        font-size: 1.5rem;
    }
}

@media (max-width: 768px){
  html{
      font-size: 55%;
  }
  header .fa-bars{
      display: block;
  }
  header .fa-times{
    display: block;
  }
  header .navbar {
      position: fixed;
      top:-100rem; 
      left:0;
      width: 100%;
      background: #fff;
      border-radius: 1rem;
      opacity: 0;
      transition: 0.2s linear;
  }
  header .nav-toggle{
      opacity: 1;
      top:7rem; 

  }

  header .navbar ul{
      flex-flow: column; /* 5 */
      padding: 2rem 0;
  }
  header .navbar .logo{
    font-size: 1.5rem;
  }
  header .navbar ul li{
      margin: 1rem 0;
      width: 100%;
      text-align: center;
  }
  header .navbar ul li a{
      font-size: 2rem;
      display: block;
  }
  .home .content p{
      padding: 1rem 5rem;
  }
  .home::before{
      display: none;

  }

}

/*👉@media (max-width: 900px){
    .maxcontainer{
        top:1000px;
    }
}
@media (min-width: 901px) and (max-width: 1000px){
    .maxcontainer{
        top:1200px;
    }
}
@media (min-width: 1001px){
    .maxcontainer{
        top:800px;
    }
}👈*/

@media(min-width: 768px) and (max-width: 1000px){
    .maxcontainer{
        position: absolute;
    }
    .maxcontainer{
        top:1000px;
    }
}
@media(min-width: 1400px){
    .maxcontainer{
        position: absolute;
    }
    .maxcontainer{
        top:770px;
    }
    .presentation{
        position: relative;
    }


}
@media (max-width: 768px) {
    .home {
        background-size: contain;

    }

}

/* 
SECTION WAVE BACKGROUND
*/


.underline-container {
    position: relative;
    margin-bottom: 20px;


    
}

.underline-svg {
    position: absolute;
    top: 100%;
    left: 10%;
    width: 100%;
    transform: translateY(100%);
    background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.underline-text {
    margin: 0;
    position: relative;
    z-index: 1;
    text-transform: none;
    font-family: 'Dancing Script';
    background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}




@media (max-width: 768px) {
    .firstPart{
        width: 100%;
    }
    .typing-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .underline-svg {
        position: absolute;
        top: 80%;
        left: 20%;
        transform: translateY(100%);

    }

    .about .row{
        flex-flow: column-reverse;
    }


    .about .row .image img{
        margin: 0rem !important;
        height: 100vh !important;
        width: 100vw !important;
    }


    .about .row .content{
        padding: 0rem;
        width: 100vw;
    }

    .presentation h1 .first, .second{
        color: whitesmoke !important;
        font-weight: 800 !important;
        padding: 0rem 1rem;
        font-size: 2rem;

        

    }

}
@media (min-width: 768px) {
    .presentation h1 .first, .second{
        color: whitesmoke !important;
    }
}



.sectionDesRealisations{
    

position: relative;
/* background-image: linear-gradient(to bottom, #FFFEFF 0%, #D7FFFE 100%); */
background-color: var(--sectionrealisation);
/* background: transparent; */
/* background: #F4F6F5; */
/* background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); */


}
.lottieContainer{
 
    height: 300px;
}
.titreSmall{
    color: white !important;
}
.titreRealisations{
    position: relative;
    line-height: 7rem;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 800;
    text-transform: none;
    color: #12172A;
    font-size: 4rem;
    /* background: linear-gradient(to right, var(--bleu_abysse), var(--bleu_surface)); */
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 768px){
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: 15px;
        font-size: 3rem !important;
        line-height: 3rem !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

    }

}
.titreFAQ{
    padding-top:50px;
    position: relative;
    line-height: 5rem;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 800;
    text-transform: none;
    color: #12172A;
    font-size: 4rem;
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 768px){
        padding-bottom: 15px;
        font-size: 3rem !important;
        line-height: 3rem !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

    }

}

.ctabutton{
    margin: 1rem 0;
    display: flex;
    align-items: center;
    text-align: center;
}
.ctabutton button{
    cursor: pointer;
    outline: none;
    border-radius: 50px;
    padding: 10px 20px;
    font-family: 'MontSerrat';
    text-transform: none;
    font-size: 2rem;
    background: var(--bleu_mer);
    color: #fff;
    transition: .3s linear;
    -webkit-box-shadow: 3px 3px 10px 3px #000000; 
    box-shadow: 3px 3px 10px 3px #000000;
}

.ctabutton button:hover{
    background: var(--bleu_surface);
    letter-spacing: 2px;

}





.presentation{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: none;

}
.presentation h1{

    
    position: relative;
    line-height: 7rem;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 800;
    text-transform: none;
    font-size: 3rem;
/*👉    background: linear-gradient(to right, var(--bleu_abysse), var(--bleu_surface));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;👈*/

}
.first, .second{
    text-transform: none;
}




.first::before{
    content: "";
    position: absolute;
    bottom:12px;
    left: calc(75% - 20%);
    width: 200px;
  /*👉  left: 60%;
    bottom: 17px;
    width: 3rem;👈*/
    height: 14px;
    transform: skew(-50deg) translateX(-50%);
    background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C) !important;


    z-index: -1;
    @media (min-width: 768px) {
        width: 100px !important;
        left: calc(75% - 20%) !important;
        
    }
}
/*👉@media (max-width: 768px) {
    .first::before {
        width: calc(20% + 20px); ➡ Largeur basée sur le texte ⬅
        left: calc(25% + 130px) / 2;
    }

}👈*/
.first::after{
    content: "";
    position: absolute;
    left: calc(60% - 10%);
    top: 40px;
    width: 240px;
    height: 14px;
    transform: skew(-50deg) translateX(-50%);

    background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C) !important;
    z-index: -1;
    @media (min-width: 768px) {
        width: 280px;
        
    }
}




.about{
    margin: 40rem 0rem;
}

.about .row{
    display: flex;
    justify-content: center;
    align-items: center;

}
.about .row .image img{
    margin: 5rem 0;
    height: 70vh;
    width: 50vw;

}
.about .row .content{
    padding-left: 5rem;
    text-transform: none;

}
.about .row .content h3{
    font-size: 3rem;
    color: var(--bleu_surface);
    text-transform: none;
}
.about .row .content p{
    font-size: 2rem;
    color: #999;
    padding: 1rem 0;
    text-transform: none;
}
.knowmorebutton button{
    margin: 20px;
    display: flex;
    align-items: center;
    margin: auto;
    height: 3.5rem;
    width: 17rem;
    background: var(--violet);
    color: #fff;
    border: none;
    border-radius: 5rem;
    box-shadow: 0 .3rem 1rem rgba(0, 0, 0, .3);
    cursor: pointer;
    font-size: 2rem;
    transition: 0.2s;
}
.knowmorebutton button:hover{
    letter-spacing: .2rem;
    opacity: .8;
}

.generalcont{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
/*👉    margin: 0px !important;
    padding: 0px !important;👈*/
}
/*👉.magicCard{
    width: 350px !important;
    margin: 0 !important;

}👈*/
#message{
    resize: none;
}

#sendMailSection{
    padding-top: 50px;

    font-family: "Poppins" !important;
    color:#FFFFFF;
    background-color: #1A1818;
    background-size: cover !important;
    object-fit: cover !important;
    background-attachment: fixed;
    z-index: 0;
    overflow: hidden;
    position: relative;

    @media (max-width: 768px) {
        padding-bottom: 50px !important;

    }
}




  .backtotopclass{
    color: #000000;
    background: linear-gradient(to right, #BF953F, #FCF6BA, #FBF5B7, #AA771C) !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 999999999999999999999999;
  }
  
/* =============================== FOOTER ============================*/
  .footerGen{
    background: white;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: transparent;
    /* background-image: linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% ); */

  }

  .footerContent, .secondlignp{
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    font-weight: 500;
    font-family: "Roboto";


  }
  .secondlignp{
    color: #9A6A43;
    font-weight: 500;
}
/* =============================== 404 ============================*/
.pageErreur{
    height: 90vh;
    display: flex;
    align-items:center;
    justify-content: center;
    
}
.imageQCQ{
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    filter:invert();
}
.imageLogoFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    padding: 0px 10px;
}
.imageLogoMenu{
    display: flex;
    width: 200px;
    height: 45px;

}
.imageLogoMenu img{

    background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
    -webkit-background-clip: text;

}


.first_lign_footer{
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content:center;


}
.second_lign_footer{
    display:flex;
}
.second_lign_footer p{
    font-family: "Roboto";
    font-size: 26px;
}

.legal-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* couleur de fond semi-transparente */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* assure que la fenêtre modale est au-dessus du contenu */
}

.legal-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    max-width: 80%; /* ajustez selon vos besoins */
    max-height: 80%; /* ajustez selon vos besoins */
    overflow-y: auto; /* ajoutez une barre de défilement si nécessaire */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}


/* ============ Dessiner ================ */

.dessiner {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 10px auto;
}




.titreToggle{
    padding: 20px 0px;
    text-align: center;
    color: #FFFFFF;
    font-family: "Poppins";
}
.container_canvas_bouton {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.container_canvas_bouton button {
    display: flex;
    align-items: end;
    justify-content: center;
}


.canvas-container {
    margin: auto;
    width: 100% !important; /* Utiliser la largeur du conteneur parent */
/* Limiter la largeur maximale du canvas si nécessaire */
    height: 555px; /* Définir la hauteur souhaitée */

}

canvas {
    background: #fff;
    width: 100% !important; /* Utiliser 100% de la largeur du conteneur */
    height: 555px;/* Hauteur automatique pour maintenir le ratio d'aspect */
}

/*👉@media (max-width: 768px) {
    .canvas-container {
        max-width: 85%; ➡ Largeur maximale du canvas pour les petits écrans ⬅
    }

    canvas {
        max-width: 85%;
        cursor: auto; ➡ Largeur maximale du canvas pour les petits écrans ⬅
    }
}
👈*/


.popup {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
  font-size: 18px;
}
.popup.success {
    background-color: rgba(0, 128, 0, 0.8); /* Fond vert pour succès */
  }
  
  .popup.error {
    background-color: rgba(255, 0, 0, 0.8); /* Fond rouge pour erreur */
  }


.logofr{
    height: 50px;
    width: 50px;
    padding: 10px 5px;
}

.animationbox{
height: 10% !important;

}

#portfolio{

    width: 100%;
}
.lottieFile{
    width: 300px;
    margin: auto;
}
.lottieFileSM{
    width: 300px;
    margin: auto;
}
/* Animation de zoom */
@keyframes zoomIn {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  

  @media(min-width:768px){
    .generalcont {
        opacity: 0;
        transform: scale(0.9);
        transition: transform 0.8s ease-out, opacity 0.8s ease-out;
    }

  }
  
  .generalcont.show {
    opacity: 1;
    transform: scale(1);
  }




.cut2::before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -180px;
    height: 200px;
/*👉    background-color: #AA771C;👈*/
background-color: var(--sectionrealisation);
    transform: skewY(4deg);
}

.cut3::before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -180px;
    height: 200px;
/*👉    background-color: #AA771C;👈*/
background-color: var(--sectionrealisation);
    transform: skewY(-4deg);
}

.titremail{
color: #D0D0D0;
}


.ecrisMoi{
    position: relative;
    line-height: 7rem;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 800;
    text-transform: none;
    color: #12172A;
    font-size: 4rem;
    /* background: linear-gradient(to right, var(--bleu_abysse), var(--bleu_surface)); */
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 768px){
        padding-bottom: 15px;
        font-size: 3rem !important;
        line-height: 3rem !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

    }
}


.textt{
    max-width: 400px !important;
}

.containerWords{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.motsClesPres{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.linkWatch{
    color: white;
    font-size: 16px;
    margin: 0px 0px 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconeW{
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 0px 10px;
}



/* PORTE */
.door_container{
    position: absolute;
    bottom: 2px;
    left: 10px;

}
.door_container img{
    height: 40px;
    opacity: 0.1;
    cursor: pointer;
}
.door_container img:hover{
    opacity: 1;
}
.doorPopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

.doorImageContainer {
    position: relative;
    text-align: center;
}

.centeredDoorImage {
    width: 400px; /* Ajustez selon votre image */
    margin: 0 auto;
}

.passwordForm {
    position: absolute;
    top: 50%; /* Ajustez pour positionner le formulaire */
    left: 50%;

    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.passwordInput {
    margin-bottom: 8px;
    padding: 8px;
    font-size: 16px;
    width: 85%;

}

.submitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50px;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;

    background-color: #000000;

    box-shadow: 0px 8px 15px rgba(255, 0, 0, 0.4); /* Ombre douce */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.submitButton:hover {
    box-shadow: 0px 12px 20px rgba(255, 0, 0, 0.5); /* Ombre plus forte au survol */
    transform: translateY(-3px); /* Légère élévation */
}

.submitButton:active {
    transform: translateY(1px); /* Légère pression */
    box-shadow: 0px 6px 10px rgba(255, 0, 0, 0.3); /* Ombre atténuée lors du clic */
}
.message {
    color: white;
    margin-top: 10px;
}
