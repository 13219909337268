@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Poppins:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gabriela&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');


:root{

  --couleurLettres: #C5B358;
  --couleurLys: white;
  --ombreLettres: navy;
}


.manageContainer{
  min-height: 700px;
  
    padding: 40px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1300px) {  
        display: flex;
        flex-direction: column;
        padding: 80px 0px;
    }
}
.miniaturelogo{
    width: 30px;
    height: 30px;
}
.containerh1s {
  /* 
  linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% )

      background:linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  
    background-image: linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% );
  
    background:linear-gradient( 111.4deg,  rgba(7,7,9,1) 6.5%, rgba(27,24,113,1) 93.2% );
  */
  



    background-image: url("./fond21.png");
    background-size: cover;


    background-position: center;


    position: relative;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;
    box-shadow: 5px 2px 20px 1px whitesmoke;
/*👉    box-shadow: 5px 10px 5px 0px cyan;
    -webkit-box-shadow: 6px 4px 10px 0px cyan;
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75); 👈*/  
}
.carrerouge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0%;
    z-index: 9999999;

    
}
.carrerouge img{
    height: 100%;
    width: 100%;
    object-fit: contain;
 
}
.generalcontainer { 
    margin: auto !important;
    z-index: 9;
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 1300px) {  
        display: flex;
        width: 80%;
    }
}
.textpresentationContainer{
    font-family: "Poppins";
    padding: 0px 20px;
    color: #f9b34b;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: 1300px) {
      margin-top: 20px;
        display: flex;
        width: 95%;
    }
}

.foranimtext{
  z-index: 999;
  color: var(--couleurLettres);
  font-family: "Poppins";
  @media (max-width: 768px) {
    font-size: 15px !important;
  }

}
@keyframes animation-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(9px);
    opacity: 0.1875;
  }
}
@keyframes animation-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
    opacity: 0.1875;
  }
}
@keyframes animation-3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(11px);
    opacity: 0.1875;
  }
}
@keyframes animation-4 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(12px);
    opacity: 0.1875;
  }
}
/*👉étoiel           0.0625; 0.125; 0.1875; 0.25;  0.3125;  0.375; 👈*/ 
@keyframes animation-5 { 
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(13px);
    opacity: 0.0625;
  }
}
@keyframes animation-6 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(14px);
    opacity: 0.1875;
  }
}
@keyframes animation-7 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
    opacity: 0.1875;
  }
}
@keyframes animation-8 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(16px);
    opacity: 0.1875;
  }
}
@keyframes animation-9 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(17px);
    opacity: 0.1875;
  }
}
@keyframes animation-10 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(18px);
    opacity: 0.1875;
  }
}
@keyframes animation-11 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(19px);
    opacity: 0.1875;
  }
}

/* etoile */
@keyframes animation-12 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
    opacity: 0.0625;
  }
}
@keyframes animation-13 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(21px);
    opacity: 0.1875;
  }
}
@keyframes animation-14 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(22px);
    opacity: 0.1875;
  }
}
@keyframes animation-15 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(23px);
    opacity: 0.1875;
  }
}
@keyframes animation-16 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(24px);
    opacity: 0.1875;
  }
}
@keyframes animation-17 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(25px);
    opacity: 0.1875;
  }
}
@keyframes animation-18 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(26px);
    opacity: 0.1875;
  }
}

/* etoile */
@keyframes animation-19 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(27px);
    opacity: 0.0625;
  }
}
@keyframes animation-20 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(28px);
    opacity: 0.1875;
  }
}
@keyframes animation-21 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(29px);
    opacity: 0.1875;
  }
}
@keyframes animation-22 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
    opacity: 0.1875;
  }
}
@keyframes animation-23 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(31px);
    opacity: 0.1875;
  }
}
@keyframes animation-24 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(32px);
    opacity: 0.1875;
  }
}
@keyframes animation-25 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(33px);
    opacity: 0.1875;
  }
}
@keyframes animation-26 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(34px);
    opacity: 0.1875;
  }
}
@keyframes animation-27 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(35px);
    opacity: 0.1875;
  }
}
@keyframes animation-28 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(36px);
    opacity: 0.1875;
  }
}
.foranimtext{
  color: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C) !important;


}

.foranimtext div:nth-child(1) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.2s;
  animation-timing-function: ease-in-out;

  
}
.foranimtext div:nth-child(2) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(3) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-3;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.6s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(4) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-4;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.8s;
  animation-timing-function: ease-in-out;
}
 
.foranimtext div:nth-child(5) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: var(--couleurLys);

  animation-name: animation-5;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(6) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-6;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 1.2s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(7) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-7;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 1.4s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(8) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-8;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 1.6s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(9) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-9;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 1.8s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(10) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-10;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(11) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-11;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 2.2s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(12) {
    color: var(--couleurLys);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  animation-name: animation-12;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 2.4s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(13) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);

  animation-name: animation-13;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 2.6s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(14) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-14;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 2.8s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(15) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-15;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 3s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(16) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-16;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 3.2s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(17) {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-17;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 3.4s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(18) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-18;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 3.6s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(19) {
  color: var(--couleurLys);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  animation-name: animation-19;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 3.8s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(20) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-20;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 4s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(21) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-21;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 4.2s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(22) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-22;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 4.4s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(23) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-23;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 4.6s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(24) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-24;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 4.8s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(25) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-25;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 5s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(26) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-26;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 5.2s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(27) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-shadow: 2px 2px 3px var(--ombreLettres);
  animation-name: animation-27;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 5.4s;
  animation-timing-function: ease-in-out;
}
.foranimtext div:nth-child(28) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-shadow: 2px 2px 3px var(--ombreLettres);
    animation-name: animation-27;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 5.4s;
    animation-timing-function: ease-in-out;
  }


.foranimtext{
  display: flex;
  flex-direction: row;

}

