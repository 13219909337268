body {

}
:root{
    --dynamic-color: #9e54fe; /* Valeur par défaut ou une valeur de secours */
}
.main-content {
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    background: #1a1818;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}



.notebook_logo{
    padding-top: 10%;
    display: flex;
    justify-content: center;
}
.notebook_logo img{
    display: flex;
    width: 60px;
    height: 60px;
}

h1 {
    width: 100%;
    margin: 0 auto 1.5em;
    font-size: 30px;
}
h1 small {
    text-transform: none;
    display: block;
    margin: 20px 0;
    font: 300 20px 'QuickSand', sans-serif;
    color: #999;
}
.moleskine-wrapper {
    cursor: pointer;
    max-width: calc(100% / 4);
    min-width: 10em;
}
.moleskine-notebook {
    height: 250px;
    width: 175px;
    position: relative;
    transition: 0.4s ease-in-out;
    border-radius: 5px 15px 15px 5px;
    transform-origin: left center 0px;
    display: inline-block;
    margin: 30px;
    perspective: 800px;
}
@media only screen and (max-width: 768px) {
    /* Styles spécifiques pour les smartphones */
    .moleskine-notebook {
        margin: 0px;
    }
    .moleskine-wrapper {
        max-width: calc(100% / 4);
        min-width: 10em;
        margin: 10px;
    }


  }

.moleskine-notebook:hover {
    transform: rotateZ(-10deg);
}
.moleskine-notebook:hover .notebook-cover {
    transform: rotateY(-50deg);
    z-index: 999;
    box-shadow: 20px 10px 50px rgba(0, 0, 0, 0.2);
}
.notebook-cover {


    height: 250px;
    width: 175px;
    position: absolute;
    border-radius: 5px 15px 15px 5px;
    z-index: 10;
    transition: 0.5s linear;
    transform-style: preserve-3d;
    transform-origin: left center 0px;
}
.notebook-cover:before {
    content: "";
    position: absolute;
    width: 10px;
    height: calc(100% + 2px);
    top: -1px;
    z-index: 100;
    border-radius: 2px;
    right: 25px;
    transition: 2s ease;
    background: linear-gradient(to right, #9c2e2b 0%, #cc4b48 12%, #9c2e2b 25%, #cc4b48 37%, #9c2e2b 50%, #cc4b48 62%, #9c2e2b 75%, #cc4b48 87%, #9c2e2b 100%);
}
/*👉.notebook-cover.blue {
    background: #068b97;
}
.notebook-cover.blue:before {
    background: linear-gradient(to right, #1e606e 0%, #2e95aa 12%, #1e606e 25%, #2e95aa 37%, #1e606e 50%, #2e95aa 62%, #1e606e 75%, #2e95aa 87%, #1e606e 100%);
}👈*/
/*👉.notebook-cover.green {
    background: #abc3b5;
}
.notebook-cover.green:before {
    background: linear-gradient(to right, #7ea38e 0%, #abc3b5 12%, #7ea38e 25%, #abc3b5 37%, #7ea38e 50%, #abc3b5 62%, #7ea38e 75%, #abc3b5 87%, #7ea38e 100%);
}👈*/
/*👉.notebook-cover.yellow {
    background: #9e54fe;
}
.notebook-cover.yellow:before {
    background: linear-gradient(to right, #ebb501 0%, #fed754 12%, #ebb501 25%, #fed754 37%, #ebb501 50%, #fed754 62%, #ebb501 75%, #fed754 87%, #ebb501 100%);
}👈*/
.notebook-skin {
    font-family: "Roboto";
    height: 80px;
    background: #e8e8e0;
    margin-top: 80px;
    padding: 15px;
    font-size: 15px;
    position: relative;
    z-index: 10;
    color: #222;
    text-align: left;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.notebook-skin:before {
    font-size: 10px;
    text-align: center;
    content: '\00a0Guide de survie';
    position: absolute;
    width: 100%;
    height: 15px;
    left: 0;
    bottom: 0;
    background: #cddc39;
}
.notebook-page {
    height: 100%;
    width: 175px;
    position: absolute;
    background-color: #fbfae8;
    z-index: 0;
    border-radius: 5px 16px 16px 5px;
    overflow: hidden;
}
.notebook-page.ruled {
    background: linear-gradient(to bottom, #fbfae8 9px, #e4e4e4 1px);
    background-size: 100% 10px;
}
.notebook-page.squared {
    background-image: linear-gradient(#e4e4e4 1px, transparent 1px), linear-gradient(90deg, #e4e4e4 1px, transparent 1px);
    background-size: 10px 10px, 10px 10px, 2px 2px, 2px 2px;
}
.notebook-page.dotted {
    background: linear-gradient(90deg, #fbfae8 10px, transparent 1%) center, linear-gradient(#fbfae8 10px, transparent 1%) center, #999;
    background-size: 11px 11px;
}




