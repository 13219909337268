.home{
    min-height: 110vh;
   

    object-fit: contain;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: 0;
    overflow: hidden;
    position: relative;
   
}
.megacontainerPh{
    position: relative;
    margin-bottom: 40px;
    background-color: #1a1818;  /* couleur de fonc du bloc image animée #cefeff */
    /* couleur de fonc du bloc image animée */
}






.home .content{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding-top: 14rem;
    padding-bottom: 8rem;
    text-align: center;
    opacity: 100%;
    

    @media (max-width: 768px) {
        padding-top: 5rem;
        padding-bottom: 18rem;
  }



}
.home::before{
    content: '';
    position: absolute;
    bottom: -30rem;
    left: 50%;
    transform: translateX(-50%);
    border-top: 90vh solid transparent; /* valeur correcte pour écran mac*/
    background-color: #1a1818;
    box-shadow: 5px 1px 100px 50px white;
    /* background-image: url('../public/images/fond_2_landing.webp'); */
    object-fit: contain;
    width: 130%;
    border-radius: 50%;
    z-index: -1;

    
}
/*👉.home::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px; ➡ Hauteur de la bordure inférieure ⬅
    background: linear-gradient(to right bottom, transparent 50%, rgba(0, 0, 0, 0.8) 50%); ➡ Dégradé pour créer le biais ⬅
    z-index: -1;
}👈*/

.contentspan{
    font-family:'Poppins';
    height: 160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.typing-container {
    display: flex;
    align-items: baseline; /* Aligner le contenu à la base (alignement de la ligne de base) */
    margin-left: 10px; /* Espacement entre "des applications" et le contenu du Typing */
}
.home .content .contentspan .maintitle{
    margin-top: 80px;
    font-weight: 700;
    /* text-transform: uppercase;  */
}

/*👉@media (max-width: 768px) {
    .content{
        padding-top: rem;
    }

  }👈*/






.home .content .contentspan div{
    font-family: 'MontSerrat';
    text-transform: none;
    font-size: 4rem;
    color: #fff;    
}

.maintitle, .firstPart{
    font-family: 'Poppins' !important;
    background:linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0) !important;
    
    @media (max-width: 768px) {
        font-size: 3.1rem !important;
        padding: 0px 5px;

  }
}


/*👉.homeImgAnim{
    position: absolute;
    bottom: 50%;
    z-index: 9999999999;
}👈*/

